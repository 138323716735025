let markers = [];
let map;
let mode = 'all';
let searchParams = null;
window.loadBicyclesDataTimeout = null;
let allBicycles = [];
let lastBounds = null;
let activeMarker = null;

function setupAutocomplete() {
  const input = document.getElementById('city-input');
  if (!input) return;

  const autocomplete = new google.maps.places.Autocomplete(input, { types: ['(cities)'] });
  autocomplete.bindTo('bounds', map);

  autocomplete.addListener('place_changed', function() {
    const place = autocomplete.getPlace();
    if (!place.geometry) {
      window.alert("No details available for input: '" + place.name + "'");
      return;
    }

    if (place.geometry.viewport) {
      const expandedBounds = expandBounds(place.geometry.viewport, 10000); // 10 km buffer
      map.fitBounds(expandedBounds);
    } else {
      const latLng = place.geometry.location;
      const expandedBounds = expandBoundsByLatLng(latLng, 10000); // 10 km buffer around the location
      map.fitBounds(expandedBounds);
      map.setZoom(12); // Adjust zoom level appropriately
    }

    const loadMoreButton = document.getElementById('load-more');
    if (loadMoreButton) {
      loadMoreButton.setAttribute('data-page', 2);
    }
    resetAndShowLoadMoreButton();
    loadBicyclesData();
  });
}

function resetAndShowLoadMoreButton() {
  const loadMoreButton = document.getElementById('load-more');
  if (loadMoreButton) {
    loadMoreButton.setAttribute('data-page', 2); // Réinitialiser la pagination à la première page
    loadMoreButton.style.display = 'block'; // Réafficher le bouton
  }
}

function expandBounds(bounds, distance) {
  // Convert distance from meters to latitude/longitude degrees
  const latChange = distance / 111320;
  const lngChange = distance / (111320 * Math.cos(Math.PI * bounds.getCenter().lat() / 180));

  const sw = new google.maps.LatLng(bounds.getSouthWest().lat() - latChange, bounds.getSouthWest().lng() - lngChange);
  const ne = new google.maps.LatLng(bounds.getNorthEast().lat() + latChange, bounds.getNorthEast().lng() + lngChange);

  return new google.maps.LatLngBounds(sw, ne);
}

function expandBoundsByLatLng(latLng, distance) {
  const latChange = distance / 111320;
  const lngChange = distance / (111320 * Math.cos(Math.PI * latLng.lat() / 180));

  const sw = new google.maps.LatLng(latLng.lat() - latChange, latLng.lng() - lngChange);
  const ne = new google.maps.LatLng(latLng.lat() + latChange, latLng.lng() + lngChange);

  return new google.maps.LatLngBounds(sw, ne);
}

function loadMarkers(bicycles) {

  markers.forEach(marker => {
    marker.setMap(null);
  
  if (marker === activeMarker) {
    marker.setIcon({
      url: '/assets/logo_maps.png',
      scaledSize: new google.maps.Size(22.8, 35)
    });
  }
});
markers = [];

  let infowindow = new google.maps.InfoWindow();

  bicycles.forEach(bicycle => {
    
    const marker = new google.maps.Marker({
      position: { lat: bicycle.latitude, lng: bicycle.longitude },
      map: map,
      title: bicycle.model,
      icon: {
        url: '/assets/logo_maps.png', // URL de l'image
        scaledSize: new google.maps.Size(22.8, 35) // Dimensionne l'image
      }
    });

    const contentString = `
      <div class="col text-center" style="width: 140px;">
        <img src="${bicycle.image_url || '/assets/icone_velo.png'}" alt="${bicycle.model}" class="img-fluid border-radius-lg" style="object-fit: cover; width: 100px; height: 67px;">
        <h4 class="mb-0" style="font-size:20px;">${bicycle.model}</h4>
        <p class="mb-0">${bicycle.city}</p>
        <p class="mb-0"><b>${bicycle.price_per_hour} &euro;</b> / jour</p>
        <a href="/bicycles/${bicycle.slug}" class="btn btn-secondary mb-1" aria-label="View details about ${bicycle.model}" data-turbo="false">Voir</a>
      </div>`;

    marker.addListener('click', () => {
      if (activeMarker) {
        activeMarker.setIcon({
          url: '/assets/logo_maps.png',
          scaledSize: new google.maps.Size(22.8, 35)
        });
      }

      // Mettre à jour l'icône du marqueur cliqué
      marker.setIcon({
        url: '/assets/logo_maps_beige.png', // URL de l'image active
        scaledSize: new google.maps.Size(22.8, 35)
      });
      activeMarker = marker;

      // Désactiver le listener de bounds_changed
      google.maps.event.clearListeners(map, 'bounds_changed');

    // Ferme l'InfoWindow précédemment ouvert
    infowindow.close();

    infowindow.addListener('closeclick', () => {
      if (activeMarker) {
        activeMarker.setIcon({
          url: '/assets/logo_maps.png', // Remettre l'icône normal
          scaledSize: new google.maps.Size(22.8, 35)
        });
        activeMarker = null; // Réinitialiser le marker actif
      }
    });
  
    // Met à jour le contenu de l'InfoWindow pour le marqueur cliqué
    infowindow.setContent(contentString);
    
    // Ouvre l'InfoWindow sur le marqueur cliqué
    infowindow.open({ anchor: marker, map: map, shouldFocus: false });

    // Réactiver le listener après un court délai
    setTimeout(() => {
      map.addListener('bounds_changed', debounce(handleBoundsChanged, 500));
    }, 1000);
  });

    markers.push(marker);
  });
}

export function initCategoryMap() {
  console.log("Initializing Category Map");

  if (markers.length > 0) {
    markers.forEach(marker => marker.setMap(null));
    markers = [];
  }

  const mapElement = document.getElementById('category-map');
  if (!mapElement) return;

  const customMapStyles = [
    { elementType: 'geometry', stylers: [{ color: '#f5f5f5' }] },
    { elementType: 'labels.icon', stylers: [{ visibility: 'off' }] },
    { elementType: 'labels.text.fill', stylers: [{ color: '#000000' }] }, // Noms des villes en noir
    { elementType: 'labels.text.stroke', stylers: [{ color: '#f5f5f5' }] },
    {
      featureType: 'administrative.land_parcel',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#bdbdbd' }]
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [{ color: '#eeeeee' }]
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#757575' }]
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [{ color: '#e5e5e5' }]
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [{ color: '#ffffff' }]
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#757575' }]
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [{ color: '#dadada' }]
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#616161' }]
    },
    {
      featureType: 'road.local',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#9e9e9e' }]
    },
    {
      featureType: 'transit.line',
      elementType: 'geometry',
      stylers: [{ color: '#e5e5e5' }]
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [{ color: '#92bae9' }] // Bleu pour les mers et océans
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#9e9e9e' }]
    },
    {
      featureType: 'landscape.natural.landcover', // Utilisation de beige pour les zones naturelles couvertes
      elementType: 'geometry',
      stylers: [{ color: '#f5f1ea' }]
    },
    {
      featureType: 'landscape.natural.terrain', // Utilisation de beige pour le terrain naturel
      elementType: 'geometry',
      stylers: [{ color: '#f5f1ea' }]
    }
  ];

  if (map) {
    map = null;
  }

  let zoomLevel = 6; // Zoom par défaut
  if (window.innerWidth <= 768) { // Largeur typique pour les appareils mobiles
    zoomLevel = 5; // Réduire le zoom pour les appareils mobiles
  }

  map = new google.maps.Map(mapElement, {
    center: { lat: 47.081012, lng: 2.398782 },
    zoom: zoomLevel,
    styles: customMapStyles,
  });

  setupAutocomplete();
  map.addListener('bounds_changed', function() {
    if (window.loadBicyclesDataTimeout) {
      clearTimeout(window.loadBicyclesDataTimeout);
    }
    window.loadBicyclesDataTimeout = setTimeout(loadBicyclesData, 500);
  });
  toggleDateAndDurationFields(mode === 'all');

  map.addListener('bounds_changed', debounce(handleBoundsChanged, 500));
}

let isSearchFormSubmitted = false;

document.addEventListener('DOMContentLoaded', function() {
  const searchForm = document.getElementById('search-category-form');
  if (searchForm) {
    searchForm.addEventListener('submit', function(event) {
      const startDate = document.getElementById('start-date-input').value;
      const endDate = document.getElementById('end-date-input').value;

      // Vérifiez d'abord si les dates sont renseignées
      if (!startDate && !endDate) {
        event.preventDefault(); // Empêche la soumission du formulaire
        alert('Veuillez renseigner les dates.');
        return; // Stoppe l'exécution de la fonction ici
      }

      // Empêche la soumission normale du formulaire
      event.preventDefault();

      // Mettez à jour l'état de soumission du formulaire
      if (mode === 'all') {
        isSearchFormSubmitted = true;
      } else {
        isSearchFormSubmitted = false;
      }

      // Traite les données du formulaire
      const formData = new FormData(searchForm);
      searchParams = new URLSearchParams(formData).toString();
      mode = mode === 'all' ? 'filtered' : 'all';
      searchForm.querySelector('input[type="submit"]').value = mode === 'all' ? "Rechercher" : "Tout afficher";

      // Définir isSearchFormSubmitted à true si le mode est 'filtered'
      isSearchFormSubmitted = mode === 'filtered';

      sessionStorage.setItem('startDate', startDate);
      sessionStorage.setItem('endDate', endDate);
      sessionStorage.setItem('page', 1);

      loadBicyclesData();
      
      toggleDateAndDurationFields(mode === 'all');
    });
  }
});  

function handleBoundsChanged() {
  const currentBounds = map.getBounds();
  console.log("Bounds changed");

  if (boundsChangedSignificantly(currentBounds)) {
    const loadMoreButton = document.getElementById('load-more');
  if (loadMoreButton) {
    lastBounds = currentBounds;
    loadBicyclesData(); // Make sure this function handles the reloading properly
    resetAndShowLoadMoreButton();
    }
  }
}

function boundsChangedSignificantly(newBounds) {
  if (!lastBounds) return true; // S'il n'y a pas de dernières limites, retourner true

  const changeThreshold = 10000; // Seuil de changement, ajustez selon les besoins
  const oldCenter = lastBounds.getCenter();
  const newCenter = newBounds.getCenter();

  if (google.maps.geometry && google.maps.geometry.spherical) {
    const distance = google.maps.geometry.spherical.computeDistanceBetween(oldCenter, newCenter);
    return distance > changeThreshold
  } else {
    console.error("Google Maps Geometry library is not loaded.");
    return false;
  }
}

function debounce(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this, args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

async function loadBicyclesData() {
  allBicycles = [];
  const startDate = document.getElementById('start-date-input').value;
  const endDate = document.getElementById('end-date-input').value;
  const bicycleType = document.querySelector('[data-bicycle-type]').dataset.bicycleType;
  const bicycleTypeUrl = bicycleType.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/_/g, '-').toLowerCase();

  const bounds = map.getBounds();
  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();
  const boundsParams = `ne_lat=${ne.lat()}&ne_lng=${ne.lng()}&sw_lat=${sw.lat()}&sw_lng=${sw.lng()}`;

  let searchParams = '';
  if (startDate && endDate) {
    searchParams += `&start_date=${startDate}&end_date=${endDate}`;
  }

  const jsonUrl = mode === 'all' ? `/location-velo/categorie/${bicycleTypeUrl}.json?${boundsParams}` : `/categories_filtered/${bicycleTypeUrl}.json?${searchParams}&${boundsParams}`;
  const htmlUrl = mode === 'all' ? `/location-velo/categorie/${bicycleTypeUrl}?${boundsParams}` : `/categories_filtered/${bicycleTypeUrl}?${searchParams}&${boundsParams}`;

  try {
    let page = 1;
    let totalBicycles = 0;
    let bicycles = [];

    do {
      const responseJson = await fetch(`${jsonUrl}&page=${page}`);
      const currentBicycles = await responseJson.json();
      console.log("Current :", currentBicycles)
      totalBicycles = parseInt(responseJson.headers.get('X-Total-Count'));
      console.log("Total count :", totalBicycles)

      if (currentBicycles.length === 0) break;

      bicycles = bicycles.concat(currentBicycles);
      console.log("bicycles :", bicycles)
      allBicycles = allBicycles.concat(currentBicycles);
      console.log("allBicycles :", allBicycles)

      page++;
    } while (bicycles.length < totalBicycles);

    if (bicycles.length === 0) {
      markers.forEach(marker => marker.setMap(null));
      markers = [];

      const message = "Aucun vélo disponible à ce lieu et à cette date... pour le moment !"; 
      const message_deux = "Essayez de rechercher une autre localisation ou d'autres dates ! N'hésitez pas à parler du site autour de vous afin de trouver votre bonheur la prochaine fois !";
      document.getElementById('bicycles-list').innerHTML = `<div class="text-center mt-5"><span><h4>${message}</h4></span>${message_deux}<p></div>`;
    } else {

      allBicycles = allBicycles.concat(bicycles);
      loadMarkers(allBicycles); // Mettre à jour les marqueurs pour tous les vélos chargés

      const responseHtml = await fetch(htmlUrl, {headers: {'Accept': 'text/html', 'X-Requested-With': 'XMLHttpRequest'}});
      const html = await responseHtml.text();
      document.getElementById('bicycles-list').innerHTML = html;
    }
    updateLoadMoreButtonVisibility(totalBicycles);

  } catch (error) {
    console.error('Error loading bicycles:', error);
    document.getElementById('bicycles-list').innerHTML = `<div><h4 class="text-center">Erreur lors du chargement des vélos. N'oubliez pas de sélectionner des dates afin de filtrer votre recherche !</h4></div>`;
  }

  toggleDateAndDurationFields(mode === 'all');
}

function updateLoadMoreButtonVisibility(totalBicycles) {
  const loadMoreButton = document.getElementById('load-more');
  if (!loadMoreButton) return;

    // Si la recherche par localisation est active, vérifiez le nombre de vélos chargés
    if (totalBicycles < 11) {
      loadMoreButton.style.display = 'none';
    } else {
      loadMoreButton.style.display = 'block';
    }
}

function buildBoundsParams() {
  const bounds = map.getBounds();
  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();
  return `ne_lat=${ne.lat()}&ne_lng=${ne.lng()}&sw_lat=${sw.lat()}&sw_lng=${sw.lng()}`;
}


document.addEventListener('turbo:load', function() {
  const loadMoreButton = document.getElementById('load-more');

  if (loadMoreButton) {
    loadMoreButton.addEventListener('click', async function() {
      const button = this;
      const page = button.getAttribute('data-page');
      const boundsParams = buildBoundsParams(); // Assurez-vous que cette fonction construit correctement les paramètres
      const bicycleType = document.querySelector('[data-bicycle-type]').dataset.bicycleType;
      const bicycleTypeUrl = bicycleType.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/_/g, '-').toLowerCase();

      const htmlUrl = `/location-velo/categorie/${bicycleTypeUrl}?page=${page}&${boundsParams}` 

      try {
        const responseHtml = await fetch(htmlUrl, {
          headers: { 'Accept': 'text/vnd.turbo-stream.html', 'X-Requested-With': 'XMLHttpRequest' }
        });
        const html = await responseHtml.text();
        document.getElementById('bicycles-list').insertAdjacentHTML('beforeend', html);

        console.log("X-Final-Page:", responseHtml.headers.get('X-Final-Page'));

        const isFinalPage = responseHtml.headers.get('X-Final-Page') === 'true';
        console.log("Is final page:", isFinalPage);

        const responseJson = await fetch(`/location-velo/categorie/${bicycleTypeUrl}.json?page=${page}&${boundsParams}`);
        const newBicycles = await responseJson.json();

        allBicycles = allBicycles.concat(newBicycles); // Ajoutez les nouveaux vélos à la liste globale
        loadMarkers(allBicycles);
        updateLoadMoreButtonVisibility(responseHtml);

        const nextPage = parseInt(page) + 1;
        if (!isFinalPage) {
          button.setAttribute('data-page', nextPage);
          sessionStorage.setItem('page', nextPage);
        } else {
          button.style.display = 'none'; // Si c'est la dernière page, retirez le bouton
        }
      } catch (error) {
        console.error('Error loading more bicycles:', error);
      }
    });
  }
});

function toggleDateAndDurationFields(enable) {
  const startDateInput = document.getElementById('start-date-input');
  const endDateInput = document.getElementById('end-date-input');

  if (startDateInput && endDateInput) {
    startDateInput.disabled = !enable;
    endDateInput.disabled = !enable;
  }
}